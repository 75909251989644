import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP";
import SEO from "../components/SEO/SEO";
import "./listing.css";

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/blog/" : `/blog/${currentPageNum - 1}/`;
    const nextPage = `/blog/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="pagingContainer">
        {!isFirstPage && <Link to={prevPage}>صفحه بعد</Link>}
        {!isLastPage && <Link to={nextPage}>صفحه قبل</Link>}
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
        <div className="listing-container">
          <div className="posts-container">
            <h1>بلاگ آرگو وی‌پی‌ان</h1>
            <HelmetProvider>
              <Helmet title="بلاگ آرگو وی‌پی‌ان" />
            </HelmetProvider>
            <SEO />
            <PostListing postEdges={postEdges} />
          </div>
          {this.renderPaging()}
        </div>
        <DownloadAPP />
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: {type: {eq: "blog"}, langKey: {ne: "en"}  } } 
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
